<template>
  <SideDialog
    :model-value="showWithdrawalDialog"
    :title="$t('withdraw')"
    @update:model-value="resetAndClose"
  >
    <LoadingSpinner
      v-if="portfolioSettings === undefined || loadingOngoing && withdrawalState !== WithdrawalState.CLOSE_PORTFOLIO_FLOW"
      :model-value="portfolioSettings === undefined"
    />
    <NoBankAccount
      v-else-if="!hasBankAccount"
      @done="resetAndClose"
    />
    <div
      v-else
      class="dialog"
    >
      <div
        v-if="
          withdrawalState === WithdrawalState.CHOOSING_AMOUNT
            || withdrawalState === WithdrawalState.CHOOSING_ACTION
        "
        class="dialog__view"
      >
        <AccountInput
          v-for="(alternative, index) in userAccounts"
          :key="index"
          :title="alternative.title"
          :account-name="alternative.accountName"
          :account-number="alternative.accountNumber"
        />

        <template v-if="fullWithdrawal">
          <LoadingSpinner v-if="loadingOngoing" />
          <div v-else>
            <p style="font-size: 1.25rem; font-weight: bold">
              Fullt uttag
            </p>
            <div class="dialog__available-amount-wrapper">
              <span class="dialog__available-amount-header">{{ $t("portfolio.overview.withdraw.side-dialog.totalAmount")
              }}:</span>
              <span class="dialog__available-amount-value">{{ displayFullWithdrawalAmount }}</span>
            </div>
            <div class="dialog__full-withdrawal-text">
              {{
                isCompany
                  ? $t("portfolio.overview.withdraw.side-dialog.fullWithdrawalCompanyText")
                  : $t("portfolio.overview.withdraw.side-dialog.fullWithdrawalText")
              }}
            </div>
          </div>
        </template>
        <div
          v-else
          class="dialog__text-field-wrapper"
        >
          <template v-if="withdrawalState === WithdrawalState.CHOOSING_AMOUNT">
            <LoadingSpinner v-if="loadingOngoing" />
            <v-form
              v-else
              ref="form"
              @submit.prevent="signAmount"
            >
              <v-text-field
                v-model="displayWithdrawAmount"
                data-jest="amount-text-field"
                data-cy="withdrawAmountTextField"
                type="text"
                variant="underlined"
                :label="$t('sum')"
                :suffix="`${$t('currency')}`"
                :rules="[rules.number, rules.leadingZeros, rules.depositMinimum, withdrawalMaximum]"
                name="withdraw"
                validate-on="blur"
                class="dialog__text-field"
              />
              <div class="dialog__available-amount-wrapper">
                <span class="dialog__available-amount-header">{{ $t("ongoingWithdrawal") }}:</span>
                <span class="dialog__available-amount-value">{{ displayTotalOngoing }}</span>
              </div>
              <div
                v-if="isCompany"
                class="dialog__available-amount-wrapper"
              >
                <span class="dialog__available-amount-header">{{ $t("portfolio.overview.withdraw.side-dialog.canBeWithdrawn") }}:</span>
                <span class="dialog__available-amount-value">{{ displayAmountCanBeWithdrawn }}</span>
              </div>

              <div
                v-else
                class="dialog__available-amount-wrapper"
              >
                <span class="dialog__available-amount-header">{{ $t("portfolio.overview.withdraw.side-dialog.availableAmount") }}:</span>
                <span class="dialog__available-amount-value">{{
                  displayMaxCanWithdrawWithoutSell
                }}</span>
              </div>
              <div
                v-if="!isCompany"
                class="dialog__available-amount-wrapper"
              >
                <span class="dialog__available-amount-header">{{ $t("portfolio.overview.withdraw.side-dialog.totalAmount") }}:</span>
                <span class="dialog__available-amount-value">{{ displayAvailableAmount }}</span>
              </div>
            </v-form>
          </template>
        </div>
        <div
          v-if="hasOngoingFullWithdrawal"
          class="dialog__company-info-text"
        >
          {{ $t("portfolio.overview.withdraw.side-dialog.ongoingFullWithdrawal") }}
        </div>
        <div
          v-else-if="withdrawalState === WithdrawalState.CHOOSING_ACTION && isCompany"
          class="dialog__company-info-text"
        >
          {{
            firstYearPortfolio
              ? $t("portfolio.overview.withdraw.side-dialog.companyReservedCashFirstYear")
              : $t("portfolio.overview.withdraw.side-dialog.companyReservedCash")
          }}
        </div>

        <div
          v-if="withdrawalState === WithdrawalState.CHOOSING_ACTION"
          class="dialog__button-wrapper dialog__button-wrapper--divided"
        >
          <CommonButton
            :disabled="hasOngoingFullWithdrawal"
            secondary
            size-large
            data-jest="withdraw-button"
            data-cy="withdrawButton"
            @click="() => (withdrawalState = WithdrawalState.CHOOSING_AMOUNT)"
          >
            {{ $t("portfolio.overview.withdraw.side-dialog.chooseAmount") }}
          </CommonButton>
          <CommonButton
            :disabled="hasOngoingFullWithdrawal || (isCompany && firstYearPortfolio)"
            size-large
            data-jest="withdraw-all-button"
            @click="() => selectFullWithdrawal()"
          >
            {{ $t("portfolio.overview.withdraw.side-dialog.withdrawAll") }}
          </CommonButton>
        </div>
        <div
          v-else
          class="dialog__button-wrapper"
        >
          <div
            v-if="cantWithdraw"
            class="dialog__sell-withdrawal-text"
          >
            {{ cantWithdrawText }}
          </div>
          <CommonButton
            class="dialog__single-button"
            :disabled="cantWithdraw || loadingOngoing"
            size-large
            data-jest="sign-button"
            data-cy="withdrawSignButton"
            @click="() => signAmount()"
          >
            {{ $t("signBankId") }}
          </CommonButton>
        </div>
      </div>
      <div
        v-if="withdrawalState === WithdrawalState.SIGNING"
        class="dialog__view"
      >
        <BankId
          @complete="signComplete"
          @canceled="signCancel"
          @failed="signFailed"
        />
      </div>
      <div
        v-else-if="withdrawalState === WithdrawalState.CONFIRM_FULL_WITHDRAWAL"
        class="dialog__view"
      >
        <div>
          <p style="font-size: 1.25rem; font-weight: bold">
            Fullt uttag
          </p>
          <div class="dialog__available-amount-wrapper">
            <span class="dialog__available-amount-header">{{ $t("portfolio.overview.withdraw.side-dialog.totalAmount")
            }}:</span>
            <span class="dialog__available-amount-value">{{ displayFullWithdrawalAmount }}</span>
          </div>
          <div class="dialog__full-withdrawal-text">
            {{
              isCompany
                ? $t("portfolio.overview.withdraw.side-dialog.fullWithdrawalCompanyText")
                : $t("portfolio.overview.withdraw.side-dialog.fullWithdrawalText")
            }}
          </div>
        </div>
        <div class="dialog__button-wrapper">
          <CommonButton
            size-large
            data-jest="sign-button"
            data-cy="withdrawSignButton"
            @click="() => signWithdraw()"
          >
            {{ $t("signBankId") }}
          </CommonButton>
        </div>
      </div>
      <div
        v-else-if="withdrawalState === WithdrawalState.CLOSE_PORTFOLIO"
        class="dialog__done-wrapper"
      >
        <div class="dialog__subheader">
          {{ isCompany ? $t("component.closePortfolioFlow.companyClosePortfolioHeading") : $t("component.closePortfolioFlow.wantToClose") }}
        </div>

        <div class="dialog__done-body-of-copy">
          {{ isCompany ? $t("component.closePortfolioFlow.companyCloseBody") : $t("component.closePortfolioFlow.closePortfolioBody") }}
        </div>
        <div class="dialog__button-wrapper dialog__button-wrapper--divided">
          <CommonButton
            secondary
            :disabled="cantWithdraw || loadingOngoing"
            size-large
            data-jest="sign-button"
            data-cy="withdrawSignButton"
            @click="() => isCompany ? resetAndClose() : dontClosePortfolio()"
          >
            {{ isCompany ? $t("cancel") : $t("no") }}
          </CommonButton>
          <CommonButton
            :disabled="cantWithdraw || loadingOngoing"
            size-large
            data-jest="sign-button"
            data-cy="withdrawSignButton"
            @click="() => closePortfolio()"
          >
            {{ $t("portfolio.overview.withdraw.side-dialog.closePortfolio") }}
          </CommonButton>
        </div>
      </div>
      <ClosePortfolioFlow
        v-else-if="withdrawalState === WithdrawalState.CLOSE_PORTFOLIO_FLOW"
        @close="resetAndClose"
        @abort="signCancel"
      />
      <div
        v-else-if="withdrawalState === WithdrawalState.SIGN_COMPLETE"
        class="dialog__spinner-wrapper"
      >
        <span>{{ $t("creatingTransaction") }}</span>
        <LoadingSpinner />
      </div>
      <div
        v-else-if="withdrawalState === WithdrawalState.WITHDRAWAL_COMPLETE"
        class="dialog__done-wrapper"
      >
        <span class="dialog__done-header-label">{{
          $t("portfolio.overview.withdraw.side-dialog.done")
        }}</span>
        <span class="dialog__done-body-of-copy">{{ withdrawalCompleteText }}</span>
        <span
          v-if="fullWithdrawal"
          class="dialog__done-body-of-copy"
        >{{ withdrawalCompleteSecondText }}</span>

        <div class="dialog__detail-row">
          <span v-if="createdWithdrawal?.expectedCompletionDate">
            {{ $t("earliestInAccount") }}</span>
          <span
            v-if="createdWithdrawal?.expectedCompletionDate"
            data-jest="transactionAmount"
          >
            {{
              DateTime.fromISO(createdWithdrawal.expectedCompletionDate.toISOString()).toFormat(
                "yyyy-MM-dd",
              )
            }}
          </span>
        </div>
        <div class="dialog__button-wrapper">
          <CommonButton
            data-jest="button"
            class="dialog__single-button"
            data-cy="withdrawDoneButton"
            @click="resetAndClose"
          >
            {{ $t("ok") }}
          </CommonButton>
        </div>
      </div>
      <div
        v-else-if="withdrawalState === WithdrawalState.LOADING_ERROR"
        class="dialog__error-text"
      >
        {{ $t("errorContactSupport") }}
      </div>
    </div>
  </SideDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import AccountInput from "../account-input.vue";
import NoBankAccount from "./no-bank-account.vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import ClosePortfolioFlow from "@/components/close-portfolio-flow.vue";
import CommonButton from "@/components/button.vue";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import { BankIdMutation } from "@/store/bankid/mutations";
import { UserAction } from "@/store/user/action-definitions";
import { PortfolioAction } from "@/store/portfolio/actions";
import type {
  PortfolioBase,
  PortfolioSettings,
  Withdrawal,
  WithdrawalResponse,
} from "@/clients";
import { WithdrawalStatus } from "@/clients";
import { BankIdAction } from "@/store/bankid/actions";
import { trackEvent } from "@/clients/segment";
import BankId from "@/components/bank-id.vue";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import type { PortfolioState } from "@/store/portfolio/types";

interface FormattedAccount {
  title: string;
  accountName: string;
  accountNumber?: string;
}
export enum WithdrawalState {
  CHOOSING_ACTION,
  CHOOSING_AMOUNT,
  CONFIRM_FULL_WITHDRAWAL,
  SIGNING,
  SIGN_COMPLETE,
  WITHDRAWAL_COMPLETE,
  LOADING_ERROR,
  CLOSE_PORTFOLIO,
  CLOSE_PORTFOLIO_FLOW,
}
const localeStringOptions = {
  maximumFractionDigits: 2,
};
export default defineComponent({
  components: {
    AccountInput,
    SideDialog,
    CommonButton,
    BankId,
    LoadingSpinner,
    NoBankAccount,
    ClosePortfolioFlow,
  },
  data() {
    return {
      WithdrawalState,
      error: false,
      fullWithdrawal: false,
      createdWithdrawalStatus: undefined as WithdrawalStatus | undefined,
      createdWithdrawal: undefined as Withdrawal | undefined,
      DateTime,
      rules: {
        leadingZeros: (value: string) => {
          value = value.replace(/\s/g, "");
          const pattern = /^(0|[1-9][0-9]*[.,]?\d*)$/;
          return pattern.test(value) || this.$t("notValidNumber");
        },
        number: (value: string) => {
          value = value.replace(/\s/g, "");
          const pattern = /^\d*[.,]?\d*$/;
          return pattern.test(value) || this.$t("notValidNumber");
        },
        depositMinimum: (value: string) => {
          value = value.replace(/\s/g, "");
          const pattern = /^[1-9][0-9]*[.,]?\d*$/;
          return pattern.test(value) || this.$t("numberGreaterThanZero");
        },
      },
      withdrawalState: WithdrawalState.CHOOSING_ACTION,
    };
  },
  computed: {
    ...mapGetters(["totalValue", "cash", "isCompany", "hasBankAccount", "isTestUser"]),
    loadingOngoing(): boolean {
      return this.$store.state.portfolioStore.loadingOngoingWithdrawals;
    },
    maxCanWithdrawWithoutSell(): number | undefined {
      const { availableCashForWithdrawal } = this.$store.state.portfolioStore;
      if (availableCashForWithdrawal !== undefined && this.ongoingWithdrawalSum !== undefined) {
        if (this.ongoingWithdrawalSum > availableCashForWithdrawal) {
          return 0;
        }
        return availableCashForWithdrawal - this.ongoingWithdrawalSum;
      }
      return undefined;
    },
    displayAvailableAmount(): string {
      if (this.approximateAvailableAmount) {
        return `${this.approximateAvailableAmount.toLocaleString(
          "sv-SE",
          localeStringOptions,
        )} ${this.$t("currency")}`;
      }
      return `- ${this.$t("currency")}`;
    },
    displayFullWithdrawalAmount(): string {
      if (this.approximateAvailableAmount) {
        let totalAmount = this.approximateAvailableAmount;
        if (this.ongoingWithdrawalSum) {
          totalAmount += this.ongoingWithdrawalSum;
        }
        return `${totalAmount.toLocaleString("sv-SE", localeStringOptions)} ${this.$t("currency")}`;
      }
      return `- ${this.$t("currency")}`;
    },
    availableCash(): number | undefined {
      if (this.cash !== undefined && this.ongoingWithdrawalSum !== undefined) {
        if (this.ongoingWithdrawalSum > this.cash) {
          return 0;
        }
        return this.cash - this.ongoingWithdrawalSum;
      }
      return undefined;
    },
    displayMaxCanWithdrawWithoutSell(): string {
      if (this.maxCanWithdrawWithoutSell !== undefined) {
        return `${this.maxCanWithdrawWithoutSell.toLocaleString(
          "sv-SE",
          localeStringOptions,
        )} ${this.$t("currency")}`;
      }
      return `- ${this.$t("currency")}`;
    },
    displayTotalOngoing(): string {
      if (this.ongoingWithdrawalSum !== undefined) {
        return `${this.ongoingWithdrawalSum.toLocaleString("sv-SE", localeStringOptions)} ${this.$t(
          "currency",
        )}`;
      }
      return `- ${this.$t("currency")}`;
    },
    displayAmountCanBeWithdrawn(): string {
      if (this.$store.state.portfolioStore.amountCanBeWithdrawn !== undefined) {
        return `${this.$store.state.portfolioStore.amountCanBeWithdrawn.toLocaleString("sv-SE", localeStringOptions)} ${this.$t(
          "currency",
        )}`;
      }
      return `- ${this.$t("currency")}`;
    },
    amountCanBeWithdrawn(): number | undefined {
      return this.$store.state.portfolioStore.amountCanBeWithdrawn;
    },
    approximateAvailableAmount(): number | undefined {
      if (this.amountCanBeWithdrawn !== undefined && this.ongoingWithdrawalSum !== undefined) {
        return this.amountCanBeWithdrawn - this.ongoingWithdrawalSum;
      }
      return undefined;
    },
    ongoingWithdrawalSum(): number | undefined {
      return this.$store.state.portfolioStore.ongoingWithdrawalSum;
    },
    hasOngoingWithdrawalOrder(): boolean {
      return (
        this.$store.state.portfolioStore.hasSellWithdrawal
        || this.$store.state.portfolioStore.hasWithdrawalOrderSent
      );
    },
    hasOngoingFullWithdrawal(): boolean {
      return (
        this.$store.state.portfolioStore.ongoingWithdrawals?.find(
          (w: WithdrawalResponse) => w.fullWithdrawal,
        ) !== undefined
      );
    },
    cantWithdraw(): boolean {
      if (this.ongoingWithdrawalSum === undefined) {
        return true;
      }
      return (
        this.hasOngoingWithdrawalOrder
        || (this.$store.state.portfolioStore.amountCanBeWithdrawn - this.ongoingWithdrawalSum) <= 0 && this.isCompany
      );
    },
    firstYearPortfolio(): boolean {
      const todayDate = DateTime.now();
      const portfolioCreatedDate = DateTime.fromJSDate(
        this.$store.state.portfolioStore.activePortfolioBase.createdAt,
      );
      return portfolioCreatedDate > todayDate.minus({ years: 1 });
    },
    cantWithdrawText(): string {
      if (this.hasOngoingWithdrawalOrder) {
        return this.$t("portfolio.overview.withdraw.side-dialog.currentlySelling");
      }
      if (this.$store.state.portfolioStore.amountCanBeWithdrawn <= 0 && this.isCompany) {
        return this.firstYearPortfolio
          ? this.$t("portfolio.overview.withdraw.side-dialog.companyCantWithdrawFirstYear")
          : this.$t("portfolio.overview.withdraw.side-dialog.companyCantWithdraw");
      }
      return "";
    },
    withdrawAmount: {
      get(): number {
        const { withdrawAmount } = this.$store.state.portfolioStore;
        return withdrawAmount;
      },
      set(value: number) {
        this.$store.commit(PortfolioMutation.setWithdrawAmount, value);
      },
    },
    displayWithdrawAmount: {
      get(): string {
        if (this.withdrawAmount) {
          return this.withdrawAmount.toLocaleString("sv-SE");
        }
        return "";
      },
      set(value: string) {
        value = this.removeWhiteSpace(value);
        value = value.replace(",", ".");
        this.withdrawAmount = Number.parseFloat(value);
      },
    },
    portfolioSettings(): PortfolioSettings {
      const { portfolioSettings } = this.$store.state.portfolioStore;
      return portfolioSettings;
    },
    userAccounts(): Array<FormattedAccount> {
      const { activePortfolioBase } = this.$store.state.portfolioStore;
      if (activePortfolioBase && this.portfolioSettings) {
        const mappedAccounts = this.mapAccounts(activePortfolioBase);
        return mappedAccounts;
      }
      const emptyAccounts = this.getEmptyAccounts();
      return emptyAccounts;
    },
    showWithdrawalDialog: {
      set(value: boolean) {
        this.$store.commit(PortfolioMutation.setShowWithdrawalDialog, value);
      },
      get(): boolean {
        return this.$store.state.portfolioStore.showWithdrawalDialog;
      },
    },
    withdrawalCompleteText(): string {
      if (this.createdWithdrawalStatus === WithdrawalStatus.WithdrawalOrderSentToBricknode) {
        return this.$t("portfolio.overview.withdraw.side-dialog.withdrawCompleteDirectBody");
      }
      if (this.createdWithdrawalStatus === WithdrawalStatus.CompanyWithdrawalAwaitingApproval) {
        return this.$t("portfolio.overview.withdraw.side-dialog.companyWithdrawCompleteDirectBody");
      }
      return this.$t("portfolio.overview.withdraw.side-dialog.withdrawalCompleteDelayedText");
    },
    withdrawalCompleteSecondText(): string {
      if (this.isCompany) {
        if (this.firstYearPortfolio && this.fullWithdrawal) {
          return this.$t(
            "portfolio.overview.withdraw.side-dialog.companyWithdrawCompleteFirstYear",
          );
        }
      }
 else if (this.fullWithdrawal && !this.isTestUser) {
        return this.$t("portfolio.overview.withdraw.side-dialog.closePortfolioText");
      }
      return "";
    },
  },
  watch: {
    hasBankAccount: {
      immediate: true,
      async handler(value: boolean) {
        if (value) {
          try {
            await this.$store.dispatch(PortfolioAction.getOngoingWithdrawals);
          }
 catch (e) {
            this.withdrawalState = WithdrawalState.LOADING_ERROR;
          }
          if (this.withdrawalState === WithdrawalState.CHOOSING_AMOUNT && this.fullWithdrawal) {
            this.displayWithdrawAmount = this.approximateAvailableAmount
              ? this.approximateAvailableAmount.toLocaleString("sv-SE")
              : "-";
          }
        }
      },
    },
  },
  methods: {
    // The reason for having this rule declared here is because we have to access computed property.
    withdrawalMaximum(value: string): boolean | string {
      if (this.approximateAvailableAmount) {
        value = value.replace(/\s/g, "");
        return (
          Number.parseInt(value, 10) <= this.approximateAvailableAmount
          || this.$t("portfolio.overview.withdraw.side-dialog.numberExceedsMaximum")
        );
      }
      return false;
    },
    selectFullWithdrawal(): void {
      this.displayWithdrawAmount = this.approximateAvailableAmount
        ? this.approximateAvailableAmount.toLocaleString("sv-SE")
        : "-";
      if (this.isTestUser || (this.isCompany && !this.firstYearPortfolio)) {
        this.withdrawalState = WithdrawalState.CLOSE_PORTFOLIO;
      } else {
        this.withdrawalState = WithdrawalState.CONFIRM_FULL_WITHDRAWAL;
      }
      this.fullWithdrawal = true;
    },
    async signAmount(): Promise<any> {
      if (
        (this.fullWithdrawal || (await (this.$refs.form as any).validate()).valid)
        && !this.cantWithdraw
      ) {
        const withdrawEverything = (this.withdrawAmount + (this.ongoingWithdrawalSum ? this.ongoingWithdrawalSum : 0)) >= this.totalValue - 1;
        if (
          (this.fullWithdrawal || withdrawEverything)
          && (this.isTestUser || (this.isCompany && !this.firstYearPortfolio))
        ) {
          this.withdrawalState = WithdrawalState.CLOSE_PORTFOLIO;
        } else {
          this.signWithdraw();
        }
      }
    },
    async signWithdraw(): Promise<any> {
      this.$store.commit(BankIdMutation.resetBankId);
      this.withdrawalState = WithdrawalState.SIGNING;

      try {
        await this.$store.dispatch(PortfolioAction.prepareWithdraw, this.fullWithdrawal);
        await this.$store.dispatch(BankIdAction.sign);
      } catch (e) {
        this.signFailed();
        throw e;
      }
    },
    closePortfolio(): any {
      this.withdrawalState = WithdrawalState.CLOSE_PORTFOLIO_FLOW;
    },
    async dontClosePortfolio() {
      if (this.fullWithdrawal) {
        this.withdrawalState = WithdrawalState.CONFIRM_FULL_WITHDRAWAL;
      } else {
        await this.signWithdraw();
      }
    },
    getEmptyAccounts(): Array<FormattedAccount> {
      this.error = true;
      return [
        { title: this.$t("from"), accountName: "-" },
        { title: this.$t("to"), accountName: "-" },
      ];
    },
    mapAccounts(userAccounts: PortfolioBase): Array<FormattedAccount> {
      this.error = false;
      const formattedBankAccount = this.formatUserAccount(this.portfolioSettings);
      return [
        {
          title: this.$t("from"),
          accountName: userAccounts.name,
        },
        formattedBankAccount,
      ];
    },
    formatUserAccount(userPortfolioSettings: PortfolioSettings): FormattedAccount {
      const { accountName, accountNumber, bankName, clearingNumber } = userPortfolioSettings;
      return {
        title: this.$t("to"),
        accountName: accountName !== undefined ? `${accountName}, ${bankName}` : `${bankName}`,
        accountNumber: `${clearingNumber}, ${accountNumber}`,
      };
    },
    removeWhiteSpace(value: string): string {
      return value.replace(/\s/g, "");
    },
    async resetAndClose() {
      this.$store.dispatch(BankIdAction.cancelSign);
      this.withdrawalState = WithdrawalState.CHOOSING_ACTION;
      this.displayWithdrawAmount = "";
      this.showWithdrawalDialog = false;
      this.fullWithdrawal = false;
    },

    async signComplete() {
      this.withdrawalState = WithdrawalState.SIGN_COMPLETE;
      try {
        const withdrawal = await this.$store.dispatch(PortfolioAction.withdraw, {
          requestedFullWithdrawal: this.fullWithdrawal,
        });
        if (withdrawal) {
          this.createdWithdrawal = withdrawal;
          this.createdWithdrawalStatus = withdrawal.status;
          this.withdrawalState = WithdrawalState.WITHDRAWAL_COMPLETE;
          trackEvent("withdrawal", { withdrawal_amount: withdrawal.amount });
        }
      } catch (e) {
        this.$store.dispatch(
          UserAction.addSnackbarMessage,
          this.$t("portfolio.overview.withdraw.side-dialog.withdrawError"),
        );
        this.withdrawalState = WithdrawalState.CHOOSING_AMOUNT;
      }
      this.$store.dispatch(
        PortfolioAction.loadPortfolioEvents,
        (this.$store.state.portfolioStore as PortfolioState).activePortfolioBase?.id,
      );
      this.$store.dispatch(PortfolioAction.getOngoingWithdrawals);
    },
    signCancel() {
      this.withdrawalState = WithdrawalState.CHOOSING_ACTION;
    },
    signFailed() {
      this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("signError"));
      this.withdrawalState = WithdrawalState.CHOOSING_AMOUNT;
    },
  },
});
</script>

<style lang="scss" scoped>
.dialog {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__view {
    padding: 2rem;
  }
  &__subheader {
    font-size: 1.125rem;
    text-align: center;
    font-family: $heading-font-family;
  }

  &__button-wrapper {
    position: absolute;
    bottom: 2rem;
    right: 0;
    left: 0;
    display: grid;
    justify-content: center;
    grid-row-gap: 1rem;
    width: 100%;
  }

  &__company-info-text {
    text-align: center;
    position: absolute;
    bottom: 8rem;
    right: 0;
    left: 0;
    padding: 2rem 2rem;
  }

  &__single-button {
    justify-self: center;
    width: 5rem;
  }

  &__text-field {
    font-size: 1.25rem;
    font-weight: bold;
  }

  &__text-field-wrapper {
    height: 4rem;
  }

  &__available-amount-wrapper {
    display: grid;
    grid-column-gap: 1.125rem;
    grid-template-columns: auto auto;
  }

  &__available-amount-header {
    font-weight: 300;
    font-size: 0.875rem;
    font-family: $heading-font-family;
    color: rgba($nearly-black, 0.63);
  }

  &__sell-withdrawal-text {
    padding: 1rem 1.5rem;
    text-align: center;
    font-weight: 300;
    font-size: 0.875rem;
    font-family: $heading-font-family;
    color: red;
  }

  &__available-amount-value {
    text-align: right;
    font-weight: 500;
    font-size: 0.875rem;
    font-family: $heading-font-family;
    color: rgba($nearly-black, 0.63);
  }

  :deep(.v-input .v-label) {
    font-weight: 300;
  }

  :deep(.v-text-field__suffix) {
    font-weight: 300;
    font-size: 1rem !important;
  }

  &__done-header-label {
    font-size: 1.125rem;
    font-weight: 500;
  }

  &__done-wrapper {
    padding-top: 2rem;
    display: grid;
    margin: 0 auto;
    max-width: 18rem;
    grid-template-columns: auto;
    text-align: center;
    grid-row-gap: 2.25rem;
  }

  &__done-body-of-copy {
    font-weight: 300;
  }

  &__confirm-close {
    text-align: left;
    font-weight: 300;
  }

  &__confirm-list {
    padding-left: 1rem;
  }

  &__spinner-wrapper {
    padding-top: 6rem;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__full-withdrawal-text {
    text-align: center;
    margin-top: 1rem;
  }

  &__error-text {
    padding: 1rem 1.5rem;
    text-align: center;
  }

  &__detail-row {
    padding: 1rem 2rem 0rem 2rem;
    display: flex;
    justify-content: space-between;
    font-weight: 300;
  }
}

.tink-iframe {
  padding-top: 1rem;
  border-style: none;
  width: 100%;
  height: 100%;
}
</style>
